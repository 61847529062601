import { navigateToUrl } from 'single-spa';

export type EntityPermission = {
  [key: string]: string[];
};

export type ModulePermissions = {
  [key: string]: EntityPermission;
};

export type AuthTokenEventDetail =
  | {
      entityId: number;
      fullName: string;
      email: string;
      type: string;
      keyList: {
        [moduleName: string]: ModulePermissions;
      };
      token: string;
      refreshToken: string;
    }
  | undefined;

export type AuthTokenReceivedEvent = CustomEvent<AuthTokenEventDetail>;

export interface StorageHandler {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

export function handleSessionRemoved(
  sessionStorageHandler: StorageHandler
): void {
  sessionStorageHandler.removeItem('entitySession');
  navigateToUrl('/');
}

export function handleAuthTokenReceived(
  event: AuthTokenReceivedEvent,
  sessionStorageHandler: StorageHandler
): void {
  const entitySessionData = event.detail;
  if (entitySessionData) {
    sessionStorageHandler.setItem(
      'entitySession',
      JSON.stringify(entitySessionData)
    );

    if (entitySessionData.token) {
      navigateToUrl('/app');
    }
  }
}

export function handleSessionUpdated(
  event: AuthTokenReceivedEvent,
  sessionStorageHandler: StorageHandler
): void {
  const entitySessionData = event.detail;
  if (entitySessionData) {
    sessionStorageHandler.setItem(
      'entitySession',
      JSON.stringify(entitySessionData)
    );
  }
}

export function setupEventListeners(
  sessionStorageHandler: StorageHandler
): void {
  window.addEventListener(
    'entitySessionReceived',
    (event: AuthTokenReceivedEvent) =>
      handleAuthTokenReceived(event, sessionStorageHandler)
  );

  window.addEventListener('entitySessionRemoved', () =>
    handleSessionRemoved(sessionStorageHandler)
  );

  window.addEventListener(
    'entitySessionUpdated',
    (event: AuthTokenReceivedEvent) =>
      handleSessionUpdated(event, sessionStorageHandler)
  );
}
