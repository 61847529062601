import {
  registerApplication,
  start,
  LifeCycles,
  navigateToUrl,
} from 'single-spa';
import { setupEventListeners } from './auth/authEvents';

setupEventListeners(sessionStorage);

registerApplication({
  name: '@trinus/fe-core-trinus-login',
  app: () => System.import<LifeCycles>('@trinus/fe-core-trinus-login'),
  activeWhen: (location: Location) => {
    return location.pathname === '/';
  },
});

registerApplication({
  name: '@trinus/fe-core-trinus-app',
  app: () => System.import<LifeCycles>('@trinus/fe-core-trinus-app'),
  activeWhen: (location: Location) => {
    if (!sessionStorage.getItem('entitySession')) {
      navigateToUrl('/');
    }

    return location.pathname.startsWith('/app');
  },
});

start({
  urlRerouteOnly: true,
});
